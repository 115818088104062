<template>
  <b-row v-if="ready">
    <b-col :md="f.md" :cols="f.sm" v-for="(f,i) in filter" :key="i">
      <b-form-group :label="f.label+':'">
        <Input :type="f.type" v-model="f.value" :options="getOptions(f.options)" :multiple="f.multiple" :keyname="f.key"/>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import Input from './InputForm';

export default {
  name: "FilterDatatable",
  components: {    
    Input
  },
  props: ['filter'],
  data(){
    return {
      ready:false
    }
  },
  beforeMount(){
  },
  mounted() {
    //do something after mounting vue instance
    this.ready = true;
  },
  computed:{    
  },
  methods: {  
    getOptions(model){
      if(model === null){
        return [];
      }
      var check = this.$api.params[model];
      if(check === undefined){
        return [];
      }      
      return check;
    }  
  }
}
</script>
