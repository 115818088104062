<template>
  <div v-if="ready">
    <b-card v-if="customSearch === false" class="card-search" v-on:keyup.enter="datatableInit()">
      <FilterDatatable :filter="filtersTmp"/>
      <slot name="customField"></slot>
      <b-row class="justify-content-md-center">
        <b-col md="4" class="text-center">
          <b-button @click="reset()" variant="light-danger">
            <span>
              reinitialiser
            </span>            
          </b-button>
          <b-button variant="primary" @click="datatableInit()" class="ml-3">
            <span v-if="loaderSearch === false">
              rechercher
            </span>
            <i v-else class="fas fa-spinner fa-spin"></i>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="card-custom mt-3" :id="uuid">
      <template #header>
        <div class="card-title">
          <h3 class="card-label">{{titre}} ({{datatable.totalRow}})</h3>
        </div>
        <div class="card-title">
          <slot name="action"></slot>          
        </div>
      </template>
      <b-pagination class="mb-3" size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      <b-row>
        <b-col md="2">
          <select class="form-control" v-model.number="datatable.limitPage" @change="datatableInit()">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="1000">1000</option>
            <option value="5000">5000</option>
          </select>
        </b-col>
      </b-row>
      <b-table ref="datalist"  stacked="md" striped hover :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
          <slot :name="slotName" v-bind="scope"/>
        </template>
      </b-table>
      <slot name="footer"></slot>
      <b-pagination class="mt-3" size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
  </div>
</template>
<script>
import FilterDatatable from '@/components/FilterDatatable';
export default {
  name: "Datatable",
  components: {
    FilterDatatable
  },
  props:{
    route:{
      type: String,
      required: true
    },
    fields:{
      type: Array,
      required: true
    },
    sort:{
      type: Object,
      required: true
    },
    titre:{
      type: String,
      required: true
    },    
    data_filters:{
      type: Object,
      required: false,
      default: Object
    },
    route_filters:{
      type: String,
      required: false,
      default: null
    },    
  },
  data(){
    return {
      ready:false,
      customSearch:false,
      loaderSearch: false,
      datatable:{
        currentPage: 1,
        limitPage: 100,
        totalRow: 0,
        data: []
      },      
      filtersTmp: [],
      localSort: false,   
      uuid: 'data-'+this.$api.moment().unix()  
    }
  },
  computed:{
    filters(){
      var data = {};
      this.filtersTmp.forEach((f) => {
        data[f.key] = f.value;
      });
      return data;
    },
    params(){
      var tmp = {...this.data_filters, ...this.filters}      
      var params = {
        filters: tmp,
        sort: this.localSort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      return params;
    }
  },
  methods: {
    datatableInit(){      
      if(this.loaderSearch === true){
        return false;
      }
      this.loaderSearch = true;      
      this.$api.ajax(this.route, this.params,(res) => {        
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.localSort = {
        key: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
      var the = this;
      setTimeout(() => {
        document.querySelector(`#${the.uuid}`).scrollIntoView({
          behavior: 'smooth'
        });          
      }, 250)
    },
    reset(){            
      if(this.$parent)
      var route = this.route_filters === null ? this.$route.path : this.route_filters;
      this.$api.ajax('/param/filter/reset?q='+route, null, res => {
        if(res.status){
          this.filtersTmp = res.data.fields === undefined ? [] : res.data.fields;          
          if(this.$route.query.q != undefined){      
            var query = this.$route.query.q;      
            query = JSON.parse(query);          
            for (const [key, value] of Object.entries(query)) {
              var check = this.filtersTmp.find(f => f.key === key)
              if(check != undefined){
                check.value = value;
              }
            }
          }        
        } 
        this.datatableInit();     
      });   
    },    
  },
  beforeMount() {
    this.localSort = JSON.parse(JSON.stringify(this.sort));
    var route = this.route_filters === null ? this.$route.path : this.route_filters;
    this.$api.ajax('/param/filter?q='+route, null, res => {
      if(res.status){
        this.filtersTmp = res.data.fields === undefined ? [] : res.data.fields;
        if(this.$route.query.q != undefined){      
          var query = this.$route.query.q;      
          query = JSON.parse(query);          
          for (const [key, value] of Object.entries(query)) {
            var check = this.filtersTmp.find(f => f.key === key)
            if(check != undefined){
              check.value = value;
            }
          }
        }        
      }      
    });    
    this.ready = true;   
  },
  mounted() {    
    setTimeout(() => {
      this.datatableInit();        
    }, 500)
  }
}
</script>
<style>
</style>
