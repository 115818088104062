<template>
  <div>
    <Datatable route="/produit/check" :fields="fields" :data_filters="filters" titre="Liste des clients" :sort="sort">      
      <template #action>
        <b-button v-b-tooltip.hover title="Creer un nouveau client" variant="success" class="btn-icon" href="/#/prospect/nouveau"><i class="fas fa-plus"></i></b-button>
      </template>
      <template #cell(titre)="data">
        <router-link :to="{path:'/produit/nouveau', query:{data:JSON.stringify(data.item)}}">{{data.value}}</router-link>
      </template>      
    </Datatable>
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
export default {
  name: "ProduitManquant",
  components: {
    Datatable
  },
  watch:{    
  },
  data(){
    return{
      filters:{},
      fields: [
        {
          key: 'titre',
          label: 'titre',
          sortable:false,
        },
        {
          key: 'mutuelle_titre',
          label: 'mutuelle_titre',
          sortable:false,
        },                  
      ],      
      sort:{
        key: 'titre',
        value: 'ASC'
      }
    }
  },
  computed:{
  },
  methods: {
  },
  beforeMount() {
  },
  mounted() {    
  }
}
</script>
<style>
</style>
